import { CHAINS } from "./chains";
import { ENV, ENVS } from "./env";

export const LINKS_BY_ENV = {
  [ENVS.dev]: {
    fulcrom: "https://pdev-fulcrom.crorc.co",
  },
  [ENVS.stag]: {
    fulcrom: "https://psta2-fulcrom.crorc.co",
  },
  [ENVS.uat]: {
    fulcrom: "https://puat-fulcrom.crolabs-int.co",
  },
  [ENVS.prod]: {
    fulcrom: "https://fulcrom.finance",
  },
};
export const LINKS_BY_CHAIN = {
  [CHAINS.CRONOS.MAINNET.ID]: {
    cronosScan: "https://cronoscan.com",
    cronosRpc:
      "https://mainnet.cronoslabs.com/v1/ea0f1c40f912a166bf1224fedd07b442",
  },
  [CHAINS.CRONOS.TESTNET.ID]: {
    cronosScan: "https://testnet.cronoscan.com",
    cronosRpc:
      "https://testnet.cronoslabs.com/v1/ea0f1c40f912a166bf1224fedd07b442",
  },
  [CHAINS.ZKSYNC.MAINNET.ID]: {
    zkSyncScan: "https://explorer.zksync.io",
    zkSyncRpc: "https://mainnet.era.zksync.io",
  },
  [CHAINS.ZKSYNC.TESTNET.ID]: {
    zkSyncScan: "https://goerli.explorer.zksync.io",
    // zkSyncRpc: "https://testnet.era.zksync.dev",// not working
    zkSyncRpc: "https://zksync-sepolia.drpc.org",
  },
  [CHAINS.CRONOS_ZKEVM.MAINNET.ID]: {
    cronosZkEvmScan: "https://explorer.zkevm.cronos.org",
    cronosZkEvmRpc: "https://mainnet.zkevm.cronos.org	",
  },
  [CHAINS.CRONOS_ZKEVM.TESTNET.ID]: {
    cronosZkEvmScan: "https://explorer.zkevm.cronos.org/testnet",
    cronosZkEvmRpc: "https://testnet.zkevm.cronos.org",
  },
};

export const LINKS = {
  ...LINKS_BY_ENV[ENV],
  fulcromDoc: "https://docs.fulcrom.finance/",
  discord: "https://discord.gg/7cTMWnpAfd",
  telegram: "https://t.me/FulcromFinance",
  twitter: "https://twitter.com/FulcromFinance",
  medium: "https://medium.com/@FulcromFinance",
  termAndCond: LINKS_BY_ENV[ENV].fulcrom + "/docs/termsandconditions.pdf",
};
