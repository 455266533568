import { isProd, isUat } from "./env";

export const CHAINS = {
  CRONOS: {
    NAME: "cronos",
    MAINNET: {
      ID: 25,
      LABEL: "Mainnet",
    },
    TESTNET: {
      ID: 338,
      LABEL: "Testnet",
    },
  },
  ZKSYNC: {
    NAME: "zkSync",
    MAINNET: {
      ID: 324,
      LABEL: "Mainnet",
    },
    TESTNET: {
      ID: 280,
      LABEL: "Testnet",
    },
  },
  CRONOS_ZKEVM: {
    NAME: "CronosZkEvm",
    MAINNET: {
      ID: 388,
      LABEL: "Mainnet",
    },
    TESTNET: {
      ID: 282,
      LABEL: "Testnet",
    },
  },
};

export const FLATTENED_CHAINS = {
  Cronos: CHAINS.CRONOS.MAINNET.ID,
  CronosTestnet: CHAINS.CRONOS.TESTNET.ID,
  ZKSync: CHAINS.ZKSYNC.MAINNET.ID,
  ZKSyncTestnet: CHAINS.ZKSYNC.TESTNET.ID,
  CronosZkEvm: CHAINS.CRONOS_ZKEVM.MAINNET.ID,
  CronosZkEvmTestnet: CHAINS.CRONOS_ZKEVM.TESTNET.ID,
};

export const CRONOS_CHAIN_BY_ENV =
  isProd || isUat ? CHAINS.CRONOS.MAINNET.ID : CHAINS.CRONOS.TESTNET.ID;
export const ZKSYNC_CHAIN_BY_ENV =
  isProd || isUat ? CHAINS.ZKSYNC.MAINNET.ID : CHAINS.ZKSYNC.TESTNET.ID;
export const CRONOS_ZKEVM_CHAIN_BY_ENV =
  isProd || isUat
    ? CHAINS.CRONOS_ZKEVM.MAINNET.ID
    : CHAINS.CRONOS_ZKEVM.TESTNET.ID;

const getChainFromQueryOrEnv = (defaultChain) => {
  if (isProd || isUat) return defaultChain;

  const queryChain = new URLSearchParams(global?.window?.location?.search).get(
    "chain"
  );
  return Object.values(FLATTENED_CHAINS).includes(Number(queryChain))
    ? Number(queryChain)
    : defaultChain;
};

export const getCronosChain = () => getChainFromQueryOrEnv(CRONOS_CHAIN_BY_ENV);

export const getZKSyncChain = () => getChainFromQueryOrEnv(ZKSYNC_CHAIN_BY_ENV);

export const getCronosZkEvmChain = () =>
  getChainFromQueryOrEnv(CRONOS_ZKEVM_CHAIN_BY_ENV);
