import { useMemo, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client";
import { chain, sumBy, sortBy, maxBy, minBy } from "lodash";
import * as ethers from "ethers";

import { fillNa } from "./helpers";
import { getAddress } from "./addresses";

const { JsonRpcProvider } = ethers.providers;

import RewardReader from "../abis/RewardReader.json";
import GlpManager from "../abis/GlpManager.json";
import Token from "../abis/v1/Token.json";
import { ADDRESS_TO_TOKEN } from "./constants/tokenAddresses";
import { USD_DECIMAL } from "./constants/decimals";
import { SUBGRAPH_BY_CHAIN } from "./constants/subgraph";
import { LINKS_BY_CHAIN } from "./constants/links";
import { sumVolume } from "./utils/sumVolumne";
import { FLP_REWARD_CHANGE_DATE_START_MS } from "./constants/dates";
import {
  BTC_LP_WEIGHT,
  ETH_LP_WEIGHT,
  FLP_REWARDS_SHARE_AFTER_IGO,
  FLP_REWARDS_SHARE_BEFORE_IGO,
} from "./constants/rates";
import { getCronosChain, getZKSyncChain, getCronosZkEvmChain, CHAINS } from "./constants/chains";
import { COINGECKO_TOKEN_ID_MAP } from "./constants/coingeckoTokenMap";
import useSWR from "swr";

const providers = {
  cronos: new JsonRpcProvider(LINKS_BY_CHAIN[getCronosChain()].cronosRpc),
  zkSync: new JsonRpcProvider(LINKS_BY_CHAIN[getZKSyncChain()].zkSyncRpc),
  CronosZkEvm: new JsonRpcProvider(LINKS_BY_CHAIN[getCronosZkEvmChain()].cronosZkEvmRpc),
};

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return providers[chainName];
}

function getChainId(chainName) {
  const chainId = {
    cronos: CHAINS.CRONOS.MAINNET.ID,
    zkSync: CHAINS.ZKSYNC.MAINNET.ID,
    CronosZkEvm: CHAINS.CRONOS_ZKEVM.MAINNET.ID,
  }[chainName];
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return chainId;
}

const getChain = (chainName) => {
  let chain;
  switch (chainName) {
    case CHAINS.CRONOS.NAME:
      chain = getCronosChain();
      break;
    case CHAINS.ZKSYNC.NAME:
      chain = getZKSyncChain();
      break;
    case CHAINS.CRONOS_ZKEVM.NAME:
      chain = getCronosZkEvmChain();
      break;
    default:
      chain = "";
  }

  return chain;
};

const NOW_TS = parseInt(Date.now() / 1000);
const FIRST_DATE_TS = parseInt(+new Date(2021, 7, 31) / 1000);

// TODO: This function is only used in the route
// /api/earn/:account. Consider removing it.
export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const rewardReader = new ethers.Contract(
    getAddress(chainId, "RewardReader"),
    RewardReader.abi,
    provider
  );
  const glpContract = new ethers.Contract(
    getAddress(chainId, "GLP"),
    Token.abi,
    provider
  );
  const glpManager = new ethers.Contract(
    getAddress(chainId, "GlpManager"),
    GlpManager.abi,
    provider
  );

  let depositTokens;
  let rewardTrackersForDepositBalances;
  let rewardTrackersForStakingInfo;

  if (chainId === CHAINS.CRONOS.MAINNET.ID) {
    depositTokens = [
      "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x35247165119B69A40edD5304969560D0ef486921",
      "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    ];
    rewardTrackersForDepositBalances = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
    rewardTrackersForStakingInfo = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
  } else {
    depositTokens = [
      "0x62edc0692BD897D2295872a9FFCac5425011c661",
      "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
      "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    ];
    rewardTrackersForDepositBalances = [
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    ];
    rewardTrackersForStakingInfo = [
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x9e295B5B976a184B14aD8cd72413aD846C299660",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    ];
  }

  const [balances, stakingInfo, glpTotalSupply, glpAum, gmxPrice] =
    await Promise.all([
      rewardReader.getDepositBalances(
        account,
        depositTokens,
        rewardTrackersForDepositBalances
      ),
      rewardReader
        .getStakingInfo(account, rewardTrackersForStakingInfo)
        .then((info) => {
          return rewardTrackersForStakingInfo.map((_, i) => {
            return info.slice(i * 5, (i + 1) * 5);
          });
        }),
      glpContract.totalSupply(),
      glpManager.getAumInUsdg(true),
      fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=gmx&vs_currencies=usd"
      ).then(async (res) => {
        const j = await res.json();
        return j["gmx"]["usd"];
      }),
    ]);

  const glpPrice = glpAum / 1e18 / (glpTotalSupply / 1e18);
  const now = new Date();

  return {
    GLP: {
      stakedGLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsGMX: stakingInfo[3][0] / 1e18,
      glpPrice,
    },
    GMX: {
      stakedGMX: balances[0] / 1e18,
      stakedEsGMX: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsGMX: stakingInfo[0][0] / 1e18,
      gmxPrice,
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString(),
  };
}

// TODO: Clean up unused variables
export const tokenDecimals = {
  "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": 18, // WETH
  "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": 8, // BTC
  "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": 6, // USDC
  "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0": 18, // UNI
  "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": 6, // USDT
  "0xf97f4df75117a78c1a5a0dbb814af92458539fb4": 18, // LINK
  "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a": 18, // MIM
  "0x17fc002b466eec40dae837fc4be5c67993ddbd6f": 18, // FRAX
  "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": 18, // DAI
};

// TODO: Clean up unused variables
export const tokenSymbols = {
  // Cronos
  "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": "BTC",
  "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": "ETH",
  "0xf97f4df75117a78c1a5a0dbb814af92458539fb4": "LINK",
  "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0": "UNI",
  "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": "USDC",
  "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": "USDT",
  "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a": "MIM",
  "0x17fc002b466eec40dae837fc4be5c67993ddbd6f": "FRAX",
  "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": "DAI",
};

// TODO: Clean up unused variables
const knownSwapSources = {
  cronos: {
    "0xabbc5f99639c9b6bcb58544ddf04efa6802f4064": "GMX Router", // Router
    "0x09f77e8a13de9a35a7231028187e9fd5db8a2acb": "GMX OrderBook", // Orderbook
    "0x98a00666cfcb2ba5a405415c2bf6547c63bf5491": "GMX PositionManager A", // PositionManager old
    "0x87a4088bd721f83b6c2e5102e2fa47022cb1c831": "GMX PositionManager B", // PositionManager
    "0x75e42e6f01baf1d6022bea862a28774a9f8a4a0c": "GMX PositionManager C", // PositionManager 12 oct 2022
    "0xb87a436b93ffe9d75c5cfa7bacfff96430b09868": "GMX PositionRouter C", // PositionRouter 12 oct 2022
    "0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb": "GMX OrderExecutor", // OrderExecutor
    "0x1a0ad27350cccd6f7f168e052100b4960efdb774": "GMX FastPriceFeed A", // FastPriceFeed
    "0x11d62807dae812a0f1571243460bf94325f43bb7": "GMX PositionExecutor", // Position Executor
    "0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237": "Dodo",
    "0x11111112542d85b3ef69ae05771c2dccff4faa26": "1inch",
    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean", // OpenOceanExchangeProxy
    "0x4775af8fef4809fe10bf05867d2b038a4b5b2146": "Gelato",
    "0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1": "LiFiDiamond",
    "0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    "0xc4bed5eeeccbe84780c44c5472e800d3a5053454": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    "0xe40beb54ba00838abe076f6448b27528dd45e4f0": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    "0x9ba57a1d3f6c61ff500f598f16b97007eb02e346": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    "0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f": "JonesDAO",
    "0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059": "WardenSwap",
    "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    "0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d": "deBridge",
    "0xc30141b657f4216252dc59af2e7cdb9d8792e1b0": "socket.tech",
    "0xdd94018f54e565dbfc939f7c44a16e163faab331": "Odos Router",
  },
};

const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url]);

  return [data, loading, error];
}

export function useCoingeckoPrices(symbol, { from } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = COINGECKO_TOKEN_ID_MAP[symbol];

  const now = Date.now() / 1000;
  const days = from ? Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1 : 0;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  /** disable query if from is not provided */
  const result = useSWR(days > 0 ? url : null, () => {
    return defaultFetcher(url);
  });
  const [res, loading, error] = [result.data, result.isLoading, result.error];

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for GLP we store last price of the day
      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;
      return {
        timestamp: groupTs,
        value: item[1],
      };
    });
    return ret;
  }, [res]);

  return [data, loading, error];
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}

export function useGraph(querySource, { subgraphUrl = null, chainName } = {}) {
  const query = gql(querySource);

  if (!subgraphUrl) {
    switch (chainName) {
      case CHAINS.CRONOS.NAME:
        subgraphUrl = SUBGRAPH_BY_CHAIN[getCronosChain()].stats;
        break;
      case CHAINS.ZKSYNC.NAME:
        subgraphUrl = SUBGRAPH_BY_CHAIN[getZKSyncChain()].stats;
        break;
      case CHAINS.CRONOS_ZKEVM.NAME:
        subgraphUrl = SUBGRAPH_BY_CHAIN[getCronosZkEvmChain()].stats;
        break;
      default:
        subgraphUrl = "";
    }
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn(
          "Subgraph request failed error: %s subgraphUrl: %s",
          ex.message,
          subgraphUrl
        );
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

export function useLastBlock(chainName) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName) {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainName }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useTradersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainName }
  );
  const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {};
    }

    let feesCumulative = 0;
    return feesData?.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map(
        (dataItem) => {
          const longOpenInterest = dataItem.longOpenInterest / 1e30;
          const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
          const openInterest = longOpenInterest + shortOpenInterest;

          // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
          // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

          const profit = dataItem.profit / 1e30;
          const loss = dataItem.loss / 1e30;
          const profitCumulative = dataItem.profitCumulative / 1e30;
          const lossCumulative = dataItem.lossCumulative / 1e30;
          const pnlCumulative = profitCumulative - lossCumulative;
          const pnl = profit - loss;
          currentProfitCumulative += profit;
          currentLossCumulative -= loss;
          currentPnlCumulative += pnl;
          return {
            longOpenInterest,
            shortOpenInterest,
            openInterest,
            profit,
            loss: -loss,
            profitCumulative,
            lossCumulative: -lossCumulative,
            pnl,
            pnlCumulative,
            timestamp: dataItem.timestamp,
            currentPnlCumulative,
            currentLossCumulative,
            currentProfitCumulative,
          };
        }
      )
    : null;

  if (data) {
    const maxProfit = maxBy(data, (item) => item.profit)?.profit;
    const maxLoss = minBy(data, (item) => item.loss)?.loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl)?.pnl ?? 0;
    const minPnl = minBy(data, (item) => item.pnl)?.pnl ?? 0;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    )?.currentPnlCumulative ?? 0;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    )?.currentPnlCumulative ?? 0;

    const currentProfitCumulative =
      data[data.length - 1]?.currentProfitCumulative ?? 0;
    const currentLossCumulative = data[data.length - 1]?.currentLossCumulative ?? 0;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `;
}

// TODO: remove unused functions
export function useSwapSources({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    );

    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source =
              knownSwapSources[chainName][item.source] || item.source;
            if (!topVolumeSources.has(source)) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;
              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}

export function useTotalVolume(chainName) {
  const query = `{
    volumeStat(id: "total") {
      margin
      swap
      liquidation
      mint
      burn
    }
  }
  `;
  const [graphData, loading] = useGraph(query, { chainName });
  const volumeStat = graphData?.volumeStat;

  const sum = volumeStat
    ? sumVolume(volumeStat).div(10 ** USD_DECIMAL)
    : undefined;

  return [sum, loading];
}

function getServerHostname(chainName) {
  return "gmx-server-mainnet.uw.r.appspot.com";
}

export function useVolumeDataRequest(
  url,
  defaultValue,
  from,
  to,
  fetcher = defaultFetcher
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url, from, to]);

  return [data, loading, error];
}

export function useVolumeDataFromServer({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const [data, loading] = useVolumeDataRequest(
    `https://${getServerHostname(chainName)}/daily_volume`,
    null,
    from,
    to,
    async (url) => {
      let after;
      const ret = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const res = await (
          await fetch(url + (after ? `?after=${after}` : ""))
        ).json();
        if (res.length === 0) return ret;
        for (const item of res) {
          if (item.data.timestamp < from) {
            return ret;
          }
          ret.push(item);
        }
        after = res[res.length - 1].id;
      }
    }
  );

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp;
      if (timestamp < from || timestamp > to) {
        return memo;
      }

      let type;
      if (item.data.action === "Swap") {
        type = "swap";
      } else if (item.data.action === "SellUSDG") {
        type = "burn";
      } else if (item.data.action === "BuyUSDG") {
        type = "mint";
      } else if (item.data.action.includes("LiquidatePosition")) {
        type = "liquidation";
      } else {
        type = "margin";
      }
      const volume = Number(item.data.volume) / 1e30;
      memo[timestamp] = memo[timestamp] || {};
      memo[timestamp][type] = memo[timestamp][type] || 0;
      memo[timestamp][type] += volume;
      return memo;
    }, {});

    let cumulative = 0;
    const cumulativeByTs = {};
    return Object.keys(tmp)
      .sort()
      .map((timestamp) => {
        const item = tmp[timestamp];
        let all = 0;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        PROPS.forEach((prop) => {
          if (item[prop]) all += item[prop];
        });
        cumulative += all;
        cumulativeByTs[timestamp] = cumulative;
        return {
          timestamp,
          all,
          cumulative,
          movingAverageAll,
          ...item,
        };
      });
  }, [data, from, to]);

  return [ret, loading];
}

export function useUsersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce(
          (memo, type) => {
            memo[`new${type}Count`] = prevUniqueCountCumulative[type]
              ? item[`unique${type}CountCumulative`] -
                prevUniqueCountCumulative[type]
              : item[`unique${type}Count`];
            prevUniqueCountCumulative[type] =
              item[`unique${type}CountCumulative`];
            return memo;
          },
          {}
        );
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum:
            item.uniqueSwapCount +
            item.uniqueMarginCount +
            item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useFundingRateData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const chain = getChain(chainName);
      const symbol = ADDRESS_TO_TOKEN[chain][item.token];

      if (symbol === "MIM") {
        return memo;
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta =
        parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;

      if (
        typeof item.endFundingRate === "number" &&
        typeof item.startFundingRate === "number"
      ) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"));
  }, [graphData]);

  return [data, loading, error];
}

const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

export function useVolumeData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp = "timestamp";
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join("\n")}
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll =
          (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  return [data, loading, error];
}

export function useFeesData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      liquidation
      swap
      mint
      burn
      timestamp
    }
  }`;
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName,
  });

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      // ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30
      ret.liquidation = item.liquidation / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);

  return [feesChartData, loading, error];
}

export function useAumPerformanceData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  groupPeriod,
  chainName,
}) {
  const [feesData, feesLoading] = useFeesData({
    from,
    to,
    groupPeriod,
    chainName,
  });
  const [flpData, flpLoading] = useFlpData({
    from,
    to,
    groupPeriod,
    chainName,
  });
  const [volumeData, volumeLoading] = useVolumeData({
    from,
    to,
    groupPeriod,
    chainName,
  });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !flpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const flpItem = flpData[i];
      const volumeItem = volumeData[i];
      let apr =
        feeItem?.all && flpItem?.aum
          ? (feeItem.all / flpItem.aum) * 100 * 365 * dailyCoef
          : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage =
        volumeItem?.all && flpItem?.aum
          ? (volumeItem.all / flpItem.aum) * 100 * dailyCoef
          : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr =
      ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage =
      ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, flpData, volumeData]);

  return [data, feesLoading || flpLoading || volumeLoading];
}

export function useFlpData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName,
} = {}) {
  const timestampProp = "timestamp";
  const query = `{
    flpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdg
      flpSupply
      distributedUsd
      distributedEth
    }
  }`;
  let [data, loading, error] = useGraph(query, { chainName });

  let cumulativeDistributedUsdPerGlp = 0;
  let cumulativeDistributedEthPerGlp = 0;
  const glpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    let prevFlpSupply;
    let prevAum;

    let ret = sortBy(data.flpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdg) / 1e18;
        const flpSupply = Number(item.flpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerGlp = distributedUsd / flpSupply || 0;
        cumulativeDistributedUsdPerGlp += distributedUsdPerGlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerGlp = distributedEth / flpSupply || 0;
        cumulativeDistributedEthPerGlp += distributedEthPerGlp;

        const flpPrice = aum / flpSupply;
        const timestamp = parseInt(item[timestampProp]);

        const newItem = {
          timestamp,
          aum,
          flpSupply,
          flpPrice,
          cumulativeDistributedEthPerGlp,
          cumulativeDistributedUsdPerGlp,
          distributedUsdPerGlp,
          distributedEthPerGlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { flpSupply, aum } = item;
        if (!flpSupply) {
          flpSupply = prevFlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.flpSupplyChange = prevFlpSupply
          ? ((flpSupply - prevFlpSupply) / prevFlpSupply) * 100
          : 0;
        if (item.flpSupplyChange > 1000) {
          item.flpSupplyChange = 0;
        }
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) {
          item.aumChange = 0;
        }
        prevFlpSupply = flpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    return ret;
  }, [data]);

  return [glpChartData, loading, error];
}

export function useFlpPerformanceData(flpData, feesData, { from } = {}) {
  const [btcPrices] = useCoingeckoPrices("BTC", { from });
  const [ethPrices] = useCoingeckoPrices("ETH", { from });

  const flpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !flpData || !feesData) {
      return null;
    }

    const flpDataById = flpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    let BTC_WEIGHT = 0;
    let ETH_WEIGHT = 0;
    let AVAX_WEIGHT = 0;

    BTC_WEIGHT = BTC_LP_WEIGHT;
    ETH_WEIGHT = ETH_LP_WEIGHT;

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - AVAX_WEIGHT;
    const FLP_START_PRICE =
      flpDataById[btcPrices[0].timestamp]?.flpPrice || 1.19;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;

    let indexBtcCount = (FLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    let indexEthCount = (FLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    let indexStableCount = FLP_START_PRICE * STABLE_WEIGHT;

    const lpBtcCount = (FLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (FLP_START_PRICE * 0.5) / ethFirstPrice;

    const ret = [];
    let cumulativeFeesPerFlp = 0;
    let lastFlpItem;
    let lastFeesItem;

    let prevEthPrice = 3400;
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      prevEthPrice = ethPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      const flpItem = flpDataById[timestampGroup] || lastFlpItem;
      lastFlpItem = flpItem;

      const flpPrice = flpItem?.flpPrice;
      const flpSupply = flpItem?.flpSupply;

      const feesItem = feesDataById[timestampGroup] || lastFeesItem;
      lastFeesItem = feesItem;

      const dailyFees = feesItem?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice + indexEthCount * ethPrice + indexStableCount;

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice;
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT;
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + FLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(btcPrice / btcFirstPrice));
      const lpEthPrice =
        (lpEthCount * ethPrice + FLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(ethPrice / ethFirstPrice));

      if (dailyFees && flpSupply) {
        const FLP_REWARDS_SHARE =
          timestampGroup >= FLP_REWARD_CHANGE_DATE_START_MS / 1000
            ? FLP_REWARDS_SHARE_AFTER_IGO
            : FLP_REWARDS_SHARE_BEFORE_IGO;
        const collectedFeesPerFlp = (dailyFees / flpSupply) * FLP_REWARDS_SHARE;
        cumulativeFeesPerFlp += collectedFeesPerFlp;
      }

      let flpPlusFees = flpPrice;
      if (flpPrice && flpSupply && cumulativeFeesPerFlp) {
        flpPlusFees = flpPrice + cumulativeFeesPerFlp;
      }

      let flpApr;
      let flpPlusDistributedUsd;
      let flpPlusDistributedEth;
      if (flpItem) {
        if (flpItem.cumulativeDistributedUsdPerFlp) {
          flpPlusDistributedUsd =
            flpPrice + flpItem.cumulativeDistributedUsdPerFlp;
          // flpApr = flpItem.distributedUsdPerFlp / flpPrice * 365 * 100 // incorrect?
        }
        if (flpItem.cumulativeDistributedEthPerFlp) {
          flpPlusDistributedEth =
            flpPrice + flpItem.cumulativeDistributedEthPerFlp * ethPrice;
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        flpPrice,
        btcPrice,
        ethPrice,
        flpPlusFees,
        flpPlusDistributedUsd,
        flpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        AVAX_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((flpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: (
          (flpPlusFees / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedUsd: (
          (flpPlusDistributedUsd / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedEth: (
          (flpPlusDistributedEth / lpEthPrice) *
          100
        ).toFixed(2),

        performanceLpBtcCollectedFees: (
          (flpPlusFees / lpBtcPrice) *
          100
        ).toFixed(2),

        performanceSynthetic: ((flpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: (
          (flpPlusFees / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedUsd: (
          (flpPlusDistributedUsd / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedEth: (
          (flpPlusDistributedEth / syntheticPrice) *
          100
        ).toFixed(2),

        flpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, flpData, feesData]);

  return [flpPerformanceChartData];
}

export function useTokenStats({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = "daily",
  chainName,
} = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;
      const chain = getChain(chainName);
      const symbol = ADDRESS_TO_TOKEN[chain][token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}

export function useReferralsData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "cronos",
} = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`;
  const subgraph =
    chainName === "cronos" ? "gmx-io/gmx-arbitrum-referrals" : "";
  const [graphData, loading, error] = useGraph(query, { subgraph });

  const data = graphData
    ? sortBy(graphData.globalStats, "timestamp").map((item) => {
        const totalRebateUsd = item.totalRebateUsd / 1e30;
        const discountUsd = item.discountUsd / 1e30;
        return {
          ...item,
          volume: item.volume / 1e30,
          volumeCumulative: item.volumeCumulative / 1e30,
          totalRebateUsd,
          totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
          discountUsd,
          referrerRebateUsd: totalRebateUsd - discountUsd,
          discountUsdCumulative: item.discountUsdCumulative / 1e30,
          referralCodesCount: parseInt(item.referralCodesCount),
          referralCodesCountCumulative: parseInt(
            item.referralCodesCountCumulative
          ),
          referrersCount: parseInt(item.referrersCount),
          referrersCountCumulative: parseInt(item.referrersCountCumulative),
          referralsCount: parseInt(item.referralsCount),
          referralsCountCumulative: parseInt(item.referralsCountCumulative),
        };
      })
    : null;

  return [data, loading, error];
}
