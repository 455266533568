import { TOKEN_LABEL_MAP_FLP_POOL } from "../constants/tokenAddresses";

// for flp pool token label replacing - for the case that trade pair token diff from pool token
// case example: for zkSync, USDC display as USDC.e, for zkEvm, USDC display as vUSD in flp pool relative;
// ##### use cases: when display token relative with FLP POOL
export const getTokenLabelWithFlpPool = (symbol, chainName) => {
  if (TOKEN_LABEL_MAP_FLP_POOL[chainName]?.[symbol]) {
    return TOKEN_LABEL_MAP_FLP_POOL[chainName][symbol];
  } else {
    return symbol;
  }
};
