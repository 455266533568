export const getFieldWithHighestValue = (arr) => {
  const highestValues = {};
  arr.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (highestValues[key] === undefined || value > highestValues[key]) {
        highestValues[key] = value;
      }
    });
  });
  return Object.entries(highestValues).reduce((a, b) =>
    a[1] > b[1] ? a : b,
  )[0];
};
