import React, { useState, useEffect } from "react";
import { Route, Switch, NavLink, useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import Cronos from "./views/Cronos";
import ZKSync from "./views/ZKSync";
import CronosZkEvm from "./views/CronosZkEvm";
import Referrals from "./views/Referrals";
import Trading from "./views/Trading";
import "./App.css";
import darkLogoIcon from "./img/fulcrom.svg";
import lightLogoIcon from "./img/fulcrom.svg";
import zkSyncIcon from "./img/zksync-lite-graident.svg";
import cronosZkEvmIcon from "./img/zkCRO.svg";
import { LINKS } from "./constants/links";
import { Footer } from "./components/Footer";

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode == "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "var(--main-bg-color)" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  useEffect(() => {
    var currentURLPath = window.location.pathname;
    if (currentURLPath === "/") {
      setIsActive(true);
    }
    return history.listen((e) => {
      const newPath = e.pathname;
      if (newPath !== "/cronos") {
        setIsActive(false);
      }
    });
  }, []);

  return (
    <>
      <Switch>
        {mode && (
          <div className={cx("App", mode)}>
            {isDrawerVisible && (
              <AnimatePresence>
                {isDrawerVisible && (
                  <motion.div
                    className="App-header-backdrop"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    transition={{ duration: 0.2 }}
                    onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                  ></motion.div>
                )}
              </AnimatePresence>
            )}
            <div className="nav">
              <div className="nav-left">
                <a
                  href={LINKS.fulcrom}
                  target="_blank"
                  className="nav-logo"
                  rel="noreferrer"
                >
                  <img
                    width="87"
                    src={mode == "dark" ? darkLogoIcon : lightLogoIcon}
                  />
                </a>
                <div className="nav-link">Analytics</div>
              </div>
              <div className="nav-right">
                <a
                  href={LINKS.fulcrom}
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  APP
                </a>
                <a
                  href={LINKS.fulcromDoc}
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  DOCS
                </a>
              </div>
            </div>
            <div className="chain-switch">
              <div className="button-group">
                <NavLink
                  to="/cronos"
                  exact
                  className={`nav-link ${isActive ? "active" : ""}`}
                  activeClassName="active"
                >
                  <span className="button-icon">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <path
                        d="m10.798 0-8.61 5.001V15l8.61 5 8.61-5V5.001L10.798 0Zm6.058 13.519-6.058 3.516L4.74 13.52V6.483l6.058-3.518 6.058 3.518v7.036Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="m14.677 12.334-4.022 2.335-4.021-2.335v-4.67l4.02-2.336 4.023 2.336L13 8.637l-2.346-1.364-2.347 1.364v2.723l2.347 1.363L13 11.36l1.676.974Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                  Cronos
                </NavLink>
                <NavLink
                  to="/zkSync"
                  exact
                  className="nav-link"
                  activeClassName="active"
                >
                  <span className="button-icon">
                    <img alt="zkSync logo" src={zkSyncIcon} />
                  </span>
                  <span style={{ position: "relative", top: "-2px" }}>
                    zkSync
                  </span>
                </NavLink>
                <NavLink
                  to="/cronos_zkEVM"
                  exact
                  className="nav-link"
                  activeClassName="active"
                >
                  <span className="button-icon">
                    <img alt="Cronos zkEVM logo" src={cronosZkEvmIcon} />
                  </span>
                  <span style={{ position: "relative", top: "-2px" }}>
                    Cronos zkEVM
                  </span>
                </NavLink>
              </div>
            </div>
            <div className="content">
              <Route
                exact
                path={["/", "/cronos"]}
                render={(props) => <Cronos {...props} mode={mode} />}
              />
              <Route
                exact
                path="/zkSync"
                render={(props) => <ZKSync {...props} mode={mode} />}
              />
              <Route
                exact
                path="/cronos_zkEVM"
                render={(props) => <CronosZkEvm {...props} mode={mode} />}
              />
              <Route
                exact
                path="/referrals/:chainName"
                render={(props) => <Referrals {...props} mode={mode} />}
              />
              <Route exact path="/trading" component={Trading} />
            </div>
            <Footer />
          </div>
        )}
      </Switch>
    </>
  );
};

export default App;
