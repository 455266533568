import { CHAINS } from "./chains";
import { reverseMap } from "../utils/reverseMap";

const lowerCaseAddress = (addressObj) => {
  return Object.fromEntries(
    Object.entries(addressObj).map(([key, value]) => {
      return [key, value.toLowerCase()];
    })
  );
};

export const TOKEN_ADDRESSES = {
  // added a transform here to avoid human error
  [CHAINS.CRONOS.MAINNET.ID]: lowerCaseAddress({
    BCH: "0x7589B70aBb83427bb7049e08ee9fC6479ccB7a23",
    BTC: "0x062e66477faf219f25d27dced647bf57c3107d52",
    ETH: "0xe44fd7fcb2b1581822d0c862b68222998a0c299a",
    USDC: "0xc21223249ca28397b4b6541dffaecc539bff0c59",
    USDT: "0x66e428c3f67a68878562e79a0234c1f83c208770",
    ATOM: "0xb888d8dd1733d72681b30c00ee76bde93ae7aa93",
    ADA: "0x0e517979c2c1c1522ddb0c73905e0d39b3f990c0",
    XRP: "0xb9ce0dd29c91e02d4620f57a66700fc5e41d6d15",
    LTC: "0x9d97be214b68c7051215bb61059b4e299cd792c3",
    SOL: "0xc9DE0F3e08162312528FF72559db82590b481800",
    PEPE: "0xf868c454784048af4f857991583e34243c92ff48",
    DOGE: "0x1a8e39ae59e5556b56b76fcba98d22c9ae557396",
    SHIB: "0xbed48612bc69fa1cab67052b42a95fb30c1bcfee",
    NEAR: "0xafe470ae215e48c144c7158eae3ccf0c451cb0cb",
    WIF: "0x25e8c72d267b96e757875d8b565a42c0e3b8f12f",
  }),
  [CHAINS.CRONOS.TESTNET.ID]: lowerCaseAddress({
    BCH: "0x6C00F0C723F321eA5cd927080Cc9f322D7cD5C1d",
    BTC: "0xffc8ce84a196420d7bccdee980c65364ed1f389f",
    ETH: "0x441d72d584b16105ff1c68dc8bc4517f4dc13e55",
    USDC: "0x321106e51b78e0e9cebcfec63c5250f0f3ccb82b",
    USDT: "0x914a8825b29a04ae687625ecda20b67abd0b58b1",
    ATOM: "0xd9ceeb61010e3a16d8bc1a1ea1b9960a58c1bf59",
    ADA: "0xf455f31baa24dc183a2e9fd1d7f2cb21e1da509a",
    XRP: "0x49fdcb02adc9f895a8bb8ff9b564cf485010ba24",
    LTC: "0xa5c469c0d5a55c03d5f7db1bfc5f1bd68b8e5f44",
    SOL: "0x9AcBBB7728AD434d656042b1f57a69eF29Ac5257",
    PEPE: "0x07375e5ac45b874b8f18a45758761d7efc0079dc",
    DOGE: "0xb06896ef071eefc3aa0c14cd3f80c8af215a2659",
    SHIB: "0xe836ae7c64b504fd8cf9cbc92cc2bf30c7c6f1b4",
    NEAR: "0x65ff0b871fe06b3141e85aec7904cb5c66190e5f",
    WIF: "0x26395c6baf90720339d6a4b912ca8a366dac500a",
  }),
  [CHAINS.ZKSYNC.MAINNET.ID]: lowerCaseAddress({
    BTC: "0xbbeb516fb02a01611cbbe0453fe3c580d7281011",
    ETH: "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
    USDC: "0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4",
    USDT: "0x493257fd37edb34451f62edf8d2a0c418852ba4c",
    "USDC.native": "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
    ZK: "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
  }),
  [CHAINS.ZKSYNC.TESTNET.ID]: lowerCaseAddress({
    ETH: "0x138DCa29f0dF3492bce88f1E897a23b04c161913",
    BTC: "0x629A09e55f95e2f7aa24aa0b87549FA56b71d89f",
    USDC: "0x693cdc3d252083b342285ad9a0b9f0f74f44f361",
    USDT: "0x7d34e6ecf4d5728c838f7fd99d6785d844bbad38",
    "USDC.native": "0xa315A1de38cA8f7CD2419E2969C5C4dA4B5488EF",
    ZK: "0xf5962371b59d9f86ea32aa936e1879a4938fdc64",
  }),
  [CHAINS.CRONOS_ZKEVM.MAINNET.ID]: lowerCaseAddress({
    BTC: "0xD65E5DbA71231D35A5802bA83dC6cB6746c9758d",
    ETH: "0x271602A97027ee1dd03b1E6e5dB153eB659A80b1",
    USDC: "0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2",
  }),
  [CHAINS.CRONOS_ZKEVM.TESTNET.ID]: lowerCaseAddress({
    ETH: "0x805523E61CD98E4275B2B2db7Baa8dEC4ffb464F",
    BTC: "0xc57e3d4424eDb501B4eC8Cf22D3F55A18Ef6e879",
    USDC: "0xD21949318DDf885ea75d85C6a6702c88cbCce823",
  }),
};

export const ADDRESS_TO_TOKEN = {
  [CHAINS.CRONOS.MAINNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.CRONOS.MAINNET.ID]
  ),
  [CHAINS.CRONOS.TESTNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.CRONOS.TESTNET.ID]
  ),
  [CHAINS.ZKSYNC.MAINNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.ZKSYNC.MAINNET.ID]
  ),
  [CHAINS.ZKSYNC.TESTNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.ZKSYNC.TESTNET.ID]
  ),
  [CHAINS.CRONOS_ZKEVM.MAINNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.CRONOS_ZKEVM.MAINNET.ID]
  ),
  [CHAINS.CRONOS_ZKEVM.TESTNET.ID]: reverseMap(
    TOKEN_ADDRESSES[CHAINS.CRONOS_ZKEVM.TESTNET.ID]
  ),
};

const DISPLAY_TOKENS_TRANSFORM_ZKSYNC = {
  USDC: "USDC.e",
};

const DISPLAY_TOKENS_TRANSFORM_ZKEVM = {
  ETH: "vETH",
  USDC: "vUSD",
};

export const BORROW_RATE_ANNUALIZED_Y_AXIS_DATA_KEY = {
  [CHAINS.CRONOS.NAME]: "ETH",
  [CHAINS.ZKSYNC.NAME]: "ETH",
  [CHAINS.CRONOS_ZKEVM.NAME]: DISPLAY_TOKENS_TRANSFORM_ZKEVM["ETH"],
};

export const TOKEN_LABEL_MAP_FLP_POOL = {
  [CHAINS.CRONOS.NAME]: {},
  [CHAINS.ZKSYNC.NAME]: {
    ...DISPLAY_TOKENS_TRANSFORM_ZKSYNC,
  },
  [CHAINS.CRONOS_ZKEVM.NAME]: {
    ...DISPLAY_TOKENS_TRANSFORM_ZKEVM,
  },
};
