export const ENVS = {
  stag: "sta",
  prod: "pro",
  uat: "uat",
  dev: "dev",
};
export const ENV = Object.values(ENVS).includes(process.env.RAZZLE_DOT_ENV)
  ? process.env.RAZZLE_DOT_ENV
  : ENVS.dev;

export const isProd = ENV === ENVS.prod;
export const isUat = ENV === ENVS.uat;

if (!isProd) {
  console.log("%c%s", "color: #00e600", "ENV", ENV);
}
