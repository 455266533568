export const Download = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M19.2457 11.0588H17.4286V5.17647C17.4286 4.52941 16.9143 4 16.2857 4H11.7143C11.0857 4 10.5714 4.52941 10.5714 5.17647V11.0588H8.75429C7.73714 11.0588 7.22286 12.3294 7.94286 13.0706L13.1886 18.4706C13.6343 18.9294 14.3543 18.9294 14.8 18.4706L20.0457 13.0706C20.7657 12.3294 20.2629 11.0588 19.2457 11.0588ZM6 22.8235C6 23.4706 6.51429 24 7.14286 24H20.8571C21.4857 24 22 23.4706 22 22.8235C22 22.1765 21.4857 21.6471 20.8571 21.6471H7.14286C6.51429 21.6471 6 22.1765 6 22.8235Z"
        fill="#999999"
      />
    </svg>
  );
};
