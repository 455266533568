import { useState } from "react";

export default function useDataRange(initialFromValue, initialToValue = null) {
  const [dataRange, setDataRange] = useState({
    fromValue: initialFromValue,
    toValue: initialToValue,
  });

  return [dataRange, setDataRange];
}
