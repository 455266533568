import React from "react";
import "./footer.css";
import cronosPowerSvg from "../../img/cronos-power.svg";
import mediumSvg from "../../img/medium.svg";
import tgSvg from "../../img/telegram.svg";
import twitterSvg from "../../img/twitter.svg";
import discordSvg from "../../img/discord.svg";
import { LINKS } from "../../constants/links";
const socials = [
  {
    label: "twitter",
    url: LINKS.twitter,
    icon: twitterSvg,
  },
  {
    label: "discord",
    url: LINKS.discord,
    icon: discordSvg,
  },
  {
    label: "telegram",
    url: LINKS.telegram,
    icon: tgSvg,
  },
  {
    label: "medium",
    url: LINKS.medium,
    icon: mediumSvg,
  },
];
export const Footer = () => {
  return (
    <div className="footer">
      <div className="cronos_desc">
        <img alt="cronos" src={cronosPowerSvg} className="power_by_icon" />
        <p className="power_by_desc">
          Powered by Cronos Labs. All Rights Reserved Fulcrom. All trademarks
          and copyrights belong to their respective owners.
        </p>
        <div className="divider" />
        <a
          className="term_n_cond"
          href={LINKS.termAndCond}
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>
      </div>
      <div className="socials">
        {socials.map((social) => {
          return (
            <a href={social.url} key={social.url}>
              <img src={social.icon} alt={social.label} />
            </a>
          );
        })}
      </div>
    </div>
  );
};
