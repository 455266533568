import { useEffect, useState, useMemo } from "react";
import moment from "moment";

import { DEFAULT_GROUP_PERIOD } from "../constants/period";

import useChartDomain from "../hooks/useChartDomain";
import useDataRange from "../hooks/useDataRange";

import { getFieldWithHighestValue } from "../utils/getFieldWithHighestValue";

import {
  useVolumeData,
  useFeesData,
  useFlpData,
  useAumPerformanceData,
  useFlpPerformanceData,
  useTradersData,
  useFundingRateData,
  useUsersData,
  useLastSubgraphBlock,
  useLastBlock,
} from "../dataProvider";

const NOW = Math.floor(Date.now() / 1000);

export default function useDashboardData({ chainName }) {
  const [isExperiment, setIsExperiment] = useState(false);

  const [dataRange, setDataRange] = useDataRange(
    moment().subtract(2, "month").startOf("date").toDate()
  );

  const from = dataRange.fromValue
    ? Math.floor(+new Date(dataRange.fromValue) / 1000)
    : undefined;
  const to = dataRange.toValue
    ? Math.floor(+new Date(dataRange.toValue) / 1000)
    : NOW;

  const params = { from, to, chainName, groupPeriod: DEFAULT_GROUP_PERIOD };
  const staticParams = {
    from: undefined,
    to: NOW,
    chainName,
    groupPeriod: DEFAULT_GROUP_PERIOD,
  };

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params);

  // Volume data processing
  const [volumeData, volumeLoading] = useVolumeData(params);
  // limit to 1000 data, might not scale well
  const [totalVolumeData] = useVolumeData({ chainName });
  const [totalVolume, totalVolumeDelta] = useMemo(() => {
    if (!totalVolumeData || !totalVolumeData.length) {
      return [];
    }
    return [
      totalVolumeData[totalVolumeData.length - 1]?.cumulative,
      totalVolumeData[totalVolumeData.length - 1]?.all,
    ];
  }, [totalVolumeData]);

  // Fee data processing
  const [feesData, feesLoading] = useFeesData(params);
  const [totalFeesData] = useFeesData({ chainName });
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return [];
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative;
    const delta = total - (totalFeesData[totalFeesData.length - 2]?.cumulative ?? 0);
    return [total, delta];
  }, [totalFeesData]);

  // FLP data processing
  const [flpData, flpLoading] = useFlpData(params);
  const [flpDataStatic] = useFlpData(staticParams);

  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!flpDataStatic) {
      return [];
    }
    // Filter out objects with aum equal to 0
    const filteredData = flpDataStatic.filter((item) => item.aum !== 0);
    const total = filteredData[filteredData.length - 1]?.aum;
    const delta = total - (filteredData[filteredData.length - 2]?.aum ?? 0);
    return [total, delta];
  }, [flpDataStatic]);

  // AUM performance data processing
  const [aumPerformanceData, aumPerformanceLoading] =
    useAumPerformanceData(params);

  const [flpPerformanceData] = useFlpPerformanceData(flpData, feesData, {
    ...params,
    // use earliest available date with flp data
    from: flpData?.[0]?.timestamp
      ? Math.max(flpData[0].timestamp, from)
      : undefined,
  });

  const [minCollectedFees, maxCollectedFees] = useChartDomain(
    flpPerformanceData,
    [
      "performanceLpBtcCollectedFees",
      "performanceLpEthCollectedFees",
      "performanceSyntheticCollectedFees",
    ],
    [80, 180]
  );
  const [minGlpPrice, maxGlpPrice] = useChartDomain(
    flpPerformanceData,
    ["syntheticPrice", "glpPrice", "glpPlusFees", "lpBtcPrice", "lpEthPrice"],
    [0.4, 1.7]
  );

  const [tradersData, tradersLoading] = useTradersData(params);
  const [tradersDataStatic] = useTradersData(staticParams);
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersDataStatic) {
      return [];
    }
    const total =
      tradersDataStatic.data[tradersDataStatic.data.length - 1]?.openInterest ?? 0;
    const delta =
      total -
      (tradersDataStatic.data[tradersDataStatic.data.length - 2]?.openInterest ?? 0);
    return [total, delta];
  }, [tradersDataStatic]);

  const [usersData, usersLoading] = useUsersData(params);
  const [usersDataStatic] = useUsersData(staticParams);
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersDataStatic) {
      return [null, null];
    }
    const total =
      usersDataStatic[usersDataStatic.length - 1]?.uniqueCountCumulative;
    const prevTotal =
      usersDataStatic[usersDataStatic.length - 2]?.uniqueCountCumulative;
    const delta = total && prevTotal ? total - prevTotal : null;
    return [total, delta];
  }, [usersDataStatic]);

  const [lastSubgraphBlock, , lastSubgraphBlockError] =
    useLastSubgraphBlock(chainName);
  const [lastBlock] = useLastBlock(chainName);

  useEffect(() => {
    setIsExperiment(window.localStorage.getItem("experiment"));
  }, [setIsExperiment]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };

  const flpAumKey = useMemo(() => {
    return flpData && flpData.length > 0
      ? getFieldWithHighestValue(
          flpData.map(({ aum, flpSupply }) => ({ aum, flpSupply }))
        )
      : "flpSupply";
  }, [flpData]);

  return {
    from,
    to,
    isExperiment,
    dataRange,
    onDateRangeChange,
    fundingRateData,
    fundingRateLoading,
    volumeData,
    volumeLoading,
    totalVolume,
    totalVolumeDelta,
    feesData,
    feesLoading,
    totalFeesData,
    totalFees,
    totalFeesDelta,
    flpData,
    flpLoading,
    flpDataStatic,
    flpPerformanceData,
    totalAum,
    totalAumDelta,
    aumPerformanceData,
    aumPerformanceLoading,
    minCollectedFees,
    maxCollectedFees,
    minGlpPrice,
    maxGlpPrice,
    tradersData,
    tradersLoading,
    tradersDataStatic,
    openInterest,
    openInterestDelta,
    usersData,
    usersLoading,
    usersDataStatic,
    totalUsers,
    totalUsersDelta,
    lastSubgraphBlock,
    lastSubgraphBlockError,
    lastBlock,
    flpAumKey,
  };
}
