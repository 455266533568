import { CHAINS } from "./chains";

export const SUBGRAPH_BY_CHAIN = {
  [CHAINS.CRONOS.MAINNET.ID]: {
    stats: "https://graph.cronoslabs.com/subgraphs/name/fulcrom/stats-prod",
  },
  [CHAINS.CRONOS.TESTNET.ID]: {
    stats: "https://graph.cronoslabs.com/subgraphs/name/fulcrom/stats-testnet",
  },
  [CHAINS.ZKSYNC.MAINNET.ID]: {
    stats:
      "https://api.studio.thegraph.com/query/52869/stats-prod/version/latest",
  },
  [CHAINS.ZKSYNC.TESTNET.ID]: {
    stats:
      "https://api.studio.thegraph.com/query/52869/stats-test/version/latest",
  },
  [CHAINS.CRONOS_ZKEVM.MAINNET.ID]: {
    stats:
      "https://api.goldsky.com/api/public/project_clwrfupe2elf301wlhnd7bvva/subgraphs/fulcrom-stats-mainnet/prod/gn",
  },
  [CHAINS.CRONOS_ZKEVM.TESTNET.ID]: {
    stats:
      "https://api.goldsky.com/api/public/project_clwrfupe2elf301wlhnd7bvva/subgraphs/fulcrom-stats-testnet/test/gn",
  },
};
