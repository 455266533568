import moment from "moment";
export const FLP_REWARD_CHANGE_DATE_MS = 1679105700000; // 2023-03-18T02:15:00.000Z
export const ATOM_INCLUDED_DATE_MS = 1681171200000; // 2023-04-11
export const FLP_REWARD_CHANGE_DATE_START_MS =
  moment(FLP_REWARD_CHANGE_DATE_MS).startOf("day").unix() * 1000;
export const DATE_RANGE_OPTIONS = [
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "Last 2 Months",
    id: 2,
    isDefault: true,
  },
  {
    label: "Last 3 Months",
    id: 3,
  },
  {
    label: "All time",
    id: 4,
  },
];
