import SharedStats from "../components/SharedStats";
import { LINKS_BY_CHAIN } from "../constants/links";
import { CHAINS, getCronosZkEvmChain } from "../constants/chains";
import { TOKEN_ADDRESSES } from "../constants/tokenAddresses";

function CronosZkEvm({ mode }) {
  return (
    <SharedStats
      mode={mode}
      chainLink={LINKS_BY_CHAIN[getCronosZkEvmChain()].cronosZkEvmScan}
      chainName={CHAINS.CRONOS_ZKEVM.NAME}
      tokenItems={Object.keys(TOKEN_ADDRESSES[getCronosZkEvmChain()]).map(
        (symbol) => ({
          key: symbol,
        })
      )}
    />
  );
}

export default CronosZkEvm;
