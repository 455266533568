import SharedStats from "../components/SharedStats";
import { LINKS_BY_CHAIN } from "../constants/links";
import { CHAINS, getZKSyncChain } from "../constants/chains";
import { TOKEN_ADDRESSES } from "../constants/tokenAddresses";

function ZKSync({ mode }) {
  return (
    <SharedStats
      mode={mode}
      chainLink={LINKS_BY_CHAIN[getZKSyncChain()].zkSyncScan}
      chainName={CHAINS.ZKSYNC.NAME}
      tokenItems={Object.keys(TOKEN_ADDRESSES[getZKSyncChain()]).map(
        (symbol) => ({
          key: symbol,
        })
      )}
    />
  );
}

export default ZKSync;
