import React from "react";
import moment from "moment";
import { RiLoader5Fill } from "react-icons/ri";
import {
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Cell,
} from "recharts";
import cx from "classnames";

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  convertToPercents,
} from "../helpers";

import ChartWrapper from "../components/ChartWrapper";
import VolumeChart from "../components/VolumeChart";
import FeesChart from "../components/FeesChart";
import GenericChart from "../components/GenericChart";
import DateRangeSelect from "../components/DateRangeSelect";
import PoolAmountChart from "../components/PoolAmountChart";
import TradersProfitLossChart from "../components/TradersProfitLossChart";

import useDashboardData from "../hooks/useDashboardData";

import { DATE_RANGE_OPTIONS } from "../constants/dates";

import { formatInteger } from "../utils/formatInteger";
import { BORROW_RATE_ANNUALIZED_Y_AXIS_DATA_KEY } from "../constants/tokenAddresses";
import { getTokenLabelWithFlpPool } from "../utils/getTokenLabel";

function SharedStats({ mode, chainLink, chainName, tokenItems }) {
  const {
    from,
    to,
    isExperiment,
    dataRange,
    onDateRangeChange,
    fundingRateData,
    fundingRateLoading,
    volumeData,
    volumeLoading,
    totalVolume,
    totalVolumeDelta,
    feesData,
    feesLoading,
    totalFees,
    totalFeesDelta,
    flpData,
    flpLoading,
    flpPerformanceData,
    totalAum,
    totalAumDelta,
    aumPerformanceData,
    aumPerformanceLoading,
    minCollectedFees,
    maxCollectedFees,
    minGlpPrice,
    maxGlpPrice,
    tradersData,
    tradersLoading,
    openInterest,
    openInterestDelta,
    usersData,
    usersLoading,
    totalUsers,
    totalUsersDelta,
    lastSubgraphBlock,
    lastSubgraphBlockError,
    lastBlock,
    flpAumKey,
  } = useDashboardData({ chainName });
  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          {lastSubgraphBlock && lastBlock && (
            <p className={cx("page-description")}>
              Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`${chainLink}/block/${lastSubgraphBlock.number}`}
              >
                {lastSubgraphBlock.number}
              </a>
            </p>
          )}
          {lastSubgraphBlockError && (
            <p className="page-description warning">
              Subgraph data is temporarily unavailable.
            </p>
          )}
        </div>
      </div>
      <div className="chart-grid">
        <div className="chart-cell stats">
          {totalVolume ? (
            <>
              <div className="total-stat-label">Total Volume</div>
              <div className="total-stat-value">
                {formatNumber(totalVolume, { currency: true })}
                {totalVolumeDelta && (
                  <span
                    className="total-stat-delta plus"
                    title="Change since previous day"
                  >
                    +
                    {formatNumber(totalVolumeDelta, {
                      currency: true,
                      compact: true,
                    })}
                  </span>
                )}
              </div>
            </>
          ) : (
            <RiLoader5Fill size="3em" className="loader" />
          )}
        </div>
        <div className="chart-cell stats">
          {totalFees ? (
            <>
              <div className="total-stat-label">Total Fees</div>
              <div className="total-stat-value">
                {formatNumber(totalFees, { currency: true })}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {formatNumber(totalFeesDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : feesLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {totalAum ? (
            <>
              <div className="total-stat-label">FLP Pool</div>
              <div className="total-stat-value">
                {formatNumber(totalAum, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    totalAumDelta > 0 ? "plus" : "minus"
                  )}
                  title="Change since previous day"
                >
                  {totalAumDelta > 0 ? "+" : "-"}
                  {formatNumber(Math.abs(totalAumDelta), {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : flpLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {totalUsers ? (
            <>
              <div className="total-stat-label">Total Users</div>
              <div className="total-stat-value">
                {formatNumber(totalUsers)}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {totalUsersDelta
                    ? new Intl.NumberFormat("en-US").format(totalUsersDelta)
                    : 0}
                </span>
              </div>
            </>
          ) : usersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {openInterest ? (
            <>
              <div className="total-stat-label">Open Interest</div>
              <div className="total-stat-value">
                {formatNumber(openInterest, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    openInterestDelta > 0 ? "plus" : "minus"
                  )}
                  title="Change since previous day"
                >
                  {openInterestDelta > 0 ? "+" : "-"}
                  {formatNumber(Math.abs(openInterestDelta), {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : tradersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
      </div>
      <div className="form">
        <DateRangeSelect
          options={DATE_RANGE_OPTIONS}
          startDate={dataRange.fromValue}
          endDate={dataRange.toValue}
          onChange={onDateRangeChange}
        />
      </div>
      <div className="chart-grid">
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart
            data={feesData}
            loading={feesLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="FLP AUM & Supply"
            loading={flpLoading}
            data={flpData}
            csvFields={[{ key: "aum" }, { key: "flpSupply" }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey={flpAumKey}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={(value, name, item) => {
                    if (item.dataKey === "aum") {
                      return tooltipFormatter(value);
                    }
                    return tooltipFormatterNumber(value);
                  }}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="aum"
                  stackId="a"
                  name="AUM"
                  stroke={COLORS[0]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="flpSupply"
                  stackId="a"
                  name="FLP Supply"
                  stroke={COLORS[1]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <PoolAmountChart
            from={from}
            to={to}
            syncId="syncGlp"
            chainName={chainName}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="FLP Performance"
            loading={flpLoading}
            data={flpPerformanceData}
            csvFields={[
              { key: "syntheticPrice" },
              { key: "flpPrice" },
              { key: "flpPlusFees" },
              { key: "lpBtcPrice" },
              { key: "lpEthPrice" },
              { key: "performanceSyntheticCollectedFees" },
              { key: "indexBtcCount" },
              { key: "indexEthCount" },
              { key: "indexStableCount" },
              { key: "BTC_WEIGHT" },
              { key: "ETH_WEIGHT" },
              { key: "AVAX_WEIGHT" },
              { key: "STABLE_WEIGHT" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="performanceSyntheticCollectedFees"
                  domain={[minCollectedFees, maxCollectedFees]}
                  unit="%"
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpBtcCollectedFees"
                  name={`% LP BTC-USDC (w/ fees)`}
                  stroke={COLORS[2]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpEthCollectedFees"
                  name={`% LP ETH-USDC (w/ fees)`}
                  stroke={COLORS[4]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceSyntheticCollectedFees"
                  name="% Index (w/ fees)"
                  stroke={COLORS[0]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <span>
                <span style={{ color: COLORS[0] }}>% of Index (with fees)</span>{" "}
                is FLP with fees / Index Price * 100. Index is a basket of 20%
                BTC, 30% ETH, 25% USDC and 25% USDT rebalanced
                once&nbsp;a&nbsp;day
                <br />
                <span style={{ color: COLORS[4] }}>
                  % of LP ETH-USDC (with fees)
                </span>{" "}
                is FLP Price with fees / LP ETH-USDC * 100
                <br />
              </span>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="FLP Price Comparison"
            loading={flpLoading}
            data={flpPerformanceData}
            csvFields={[
              { key: "syntheticPrice" },
              { key: "flpPrice" },
              { key: "flpPlusFees" },
              { key: "lpBtcPrice" },
              { key: "lpEthPrice" },
              { key: "performanceSyntheticCollectedFees" },
              { key: "indexBtcCount" },
              { key: "indexEthCount" },
              { key: "indexStableCount" },
              { key: "BTC_WEIGHT" },
              { key: "ETH_WEIGHT" },
              { key: "STABLE_WEIGHT" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="flpPrice"
                  domain={[minGlpPrice, maxGlpPrice]}
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={1}
                  dot={false}
                  dataKey="syntheticPrice"
                  name="Index Price"
                  stroke={COLORS[0]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={1}
                  dot={false}
                  dataKey="flpPrice"
                  name="FLP Price"
                  stroke={COLORS[1]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="flpPlusFees"
                  name="FLP w/ fees"
                  stroke={COLORS[5]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={1}
                  dot={false}
                  dataKey="lpBtcPrice"
                  name={`LP BTC-USDC`}
                  stroke={COLORS[2]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={1}
                  dot={false}
                  dataKey="lpEthPrice"
                  name={`LP ETH-USDC`}
                  stroke={COLORS[4]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <span>
                <span style={{ color: COLORS[3] }}>FLP with fees</span> is based
                on FLP share of fees received and excluding esFUL rewards
                <br />
                <span style={{ color: COLORS[2] }}>Index Price</span> is a
                basket of 20% BTC, 30% ETH, 25% USDC and 25% USDT rebalanced
                once&nbsp;a&nbsp;day
              </span>
            </div>
          </ChartWrapper>
        </div>
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. Index" loading={flpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={flpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceSyntheticCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSynthetic"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. ETH LP" loading={flpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={flpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceLpEthCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEth"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL"
            loading={tradersLoading}
            data={tradersData?.data}
            csvFields={[
              { key: "pnl", name: "Net PnL" },
              { key: "pnlCumulative", name: "Cumulative PnL" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersData?.data} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxAbsCumulativePnl * 1.05,
                    tradersData?.stats.maxAbsCumulativePnl * 1.05,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxAbsPnl * 1.05,
                    tradersData?.stats.maxAbsPnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="Net PnL"
                >
                  {(tradersData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? "#22c761" : "#f93333"}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="Cumulative PnL"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>Considers settled (closed) positions</p>
              <p>Fees are not factored into PnL</p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart
            syncId="tradersId"
            loading={tradersLoading}
            tradersData={tradersData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={tradersLoading}
            title="Open Interest"
            data={tradersData?.data.map((item) => ({
              all: item.openInterest,
              ...item,
            }))}
            controls={{
              convertToPercents: convertToPercents,
            }}
            yaxisDataKey="all"
            items={[
              { key: "shortOpenInterest", name: "Short", color: "#f93333" },
              { key: "longOpenInterest", name: "Long", color: "#22c761" },
            ]}
            type="Bar"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={fundingRateLoading}
            title="Borrowing Rate Annualized"
            data={fundingRateData}
            yaxisDataKey={BORROW_RATE_ANNUALIZED_Y_AXIS_DATA_KEY[chainName]}
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={tokenItems}
            type="Line"
            yaxisDomain={[0, 90 /* ~87% is a maximum yearly borrow rate */]}
            isCoinChart={true}
            formatName={(symbol) => getTokenLabelWithFlpPool(symbol, chainName)}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={aumPerformanceLoading}
            title="FLP AUM Performance Annualized"
            data={aumPerformanceData}
            yaxisDataKey="apr"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "apr", name: "APR", color: COLORS[0] }]}
            description="Formula = Daily Fees / FLP Pool * 365 days * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={aumPerformanceLoading}
            title="FLP AUM Daily Usage"
            data={aumPerformanceData}
            yaxisDataKey="usage"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "usage", name: "Daily Usage", color: COLORS[4] }]}
            description="Formula = Daily Volume / FLP Pool * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="Unique Users"
            data={usersData}
            truncateYThreshold={6500}
            yaxisDataKey="uniqueSum"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={formatInteger}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "uniqueSwapCount", name: "Swaps" },
              { key: "uniqueMarginCount", name: "Margin trading" },
              { key: "uniqueMintBurnCount", name: "Mint & Burn FLP" },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="New Users"
            data={usersData?.map((item) => ({ ...item, all: item.newCount }))}
            truncateYThreshold={6000}
            yaxisDataKey="newCount"
            rightYaxisDataKey="uniqueCountCumulative"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={formatInteger}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newSwapCount", name: "Swap" },
              { key: "newMarginCount", name: "Margin trading" },
              { key: "newMintBurnCount", name: "Mint & Burn" },
              {
                key: "cumulativeNewUserCount",
                name: "Cumulative",
                type: "Line",
                yAxisId: "right",
                strokeWidth: 2,
                color: COLORS[4],
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="New vs. Existing Users"
            data={usersData?.map((item) => ({
              ...item,
              all: item.uniqueCount,
            }))}
            truncateYThreshold={7000}
            yaxisDataKey="uniqueCount"
            rightYaxisDataKey="oldPercent"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={formatInteger}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newCount", name: "New" },
              { key: "oldCount", name: "Existing" },
              {
                key: "oldPercent",
                name: "Existing %",
                yAxisId: "right",
                type: "Line",
                strokeWidth: 2,
                color: COLORS[4],
                unit: "%",
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="User Actions"
            data={(usersData || []).map((item) => ({
              ...item,
              all: item.actionCount,
            }))}
            truncateYThreshold={25000}
            yaxisDataKey="actionCount"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={formatInteger}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "actionSwapCount", name: "Swaps" },
              { key: "actionMarginCount", name: "Margin trading" },
              { key: "actionMintBurnCount", name: "Mint & Burn FLP" },
            ]}
            type="Composed"
          />
        </div>
      </div>
    </div>
  );
}

export default SharedStats;
